import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';
import 'firebase/firestore';
var config = {
  apiKey: 'AIzaSyBH8xu8AzWLBuKqKkg2unzUhzqcITNPJZA',
  authDomain: 'vendor-portal-1ad37.firebaseapp.com',
  databaseURL: 'https://vendor-portal-1ad37.firebaseio.com',
  projectId: 'vendor-portal-1ad37',
  storageBucket: 'vendor-portal-1ad37.appspot.com',
  messagingSenderId: '472025584817',
  appId: '1:472025584817:web:85d969ed0f21a209433e90',
  measurementId: 'G-J030T5SD4P',
  apiUrl: "https://us-central1-vendor-portal-1ad37.cloudfunctions.net//api"
  //apiUrl: 'https://us-central1-vendor-portal-1ad37.cloudfunctions.net//api',
};
firebase.initializeApp(config);
const db = firebase.firestore();
export default firebase;
export { firebase, config, db };
