import React from 'react';
import { Redirect } from 'react-router-dom';
import { Grid, Menu, Icon } from 'semantic-ui-react';

import UserPanel from '../util/UserPanel';

class SideMenu extends React.Component {
  state = {
    activeItem: this.props.activeItem,
  };
  handleItemClick = (e, { name }) => {
    this.setState({ ...this.state, activeItem: name });
  };

  render() {
    const { activeItem } = this.state;

    return (
      <Grid.Column
        stretched
        style={{
          background: '#2c4c92',
          paddingRight: '0',
          position: 'fixed',
          height: '100%',
          width: '240px',
          top: '0',
          left: '0',
          zIndex: '9999',
        }}
      >
        <Redirect to={activeItem} />
        <UserPanel />
        <div>
          <Menu fluid vertical inverted tabular>
            <Menu.Item name='home' active={activeItem === 'home'} onClick={this.handleItemClick}>
              <div className='sidebar-item-alignment-container'>
                <span>
                  <Icon name='home' className='sidebar-icon' />
                </span>
                <span>Home</span>
              </div>
            </Menu.Item>

            <Menu.Item name='wiki' active={activeItem === 'wiki'} onClick={this.handleItemClick}>
              <div className='sidebar-item-alignment-container'>
                <span>
                  <Icon name='question circle outline' className='sidebar-icon' />
                </span>
                <span>Wiki</span>
              </div>
            </Menu.Item>
            <Menu.Item name='sim' active={activeItem === 'sim'} onClick={this.handleItemClick}>
              <div className='sidebar-item-alignment-container'>
                <span>
                  <Icon name='balance scale' className='sidebar-icon' />
                </span>
                <span>Simulation</span>
              </div>
            </Menu.Item>
            <Menu.Item name='blog' active={activeItem === 'blog'} onClick={this.handleItemClick}>
              <div className='sidebar-item-alignment-container'>
                <span>
                  <Icon name='sticky note outline' className='sidebar-icon' />
                </span>
                <span>Blog</span>
              </div>
            </Menu.Item>
          </Menu>
        </div>
      </Grid.Column>
    );
  }
}

export default SideMenu;
