import {
  SET_SUBS,
  LOADING_DATA,
  STOP_LOADING_DATA,
  UPLOAD_SUB_STATUS,
  UPLOAD_SUB_DONE,
  UPLOAD_SUB_FAILED,
  SET_DATA_ERROR,
  CLEAR_DATA_ERROR,
  RESET_ALL_DATA,
} from '../types';

const initialState = {
  subs: [],
  loading: true,
  uploadStatus: null,
  error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_DATA:
      return {
        ...state,
        loading: true,
      };
    case STOP_LOADING_DATA:
      return {
        ...state,
        loading: false,
      };
    case SET_SUBS:
      return {
        ...state,
        subs: action.payload,
        loading: false,
      };
    case UPLOAD_SUB_STATUS:
      return {
        ...state,
        uploadStatus: action.payload,
      };
    case UPLOAD_SUB_DONE:
      return {
        ...state,
        uploadStatus: 1,
      };
    case UPLOAD_SUB_FAILED:
      return {
        ...state,
        uploadStatus: null,
      };
    case SET_DATA_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case CLEAR_DATA_ERROR:
      return {
        ...state,
        error: null,
      };
    case RESET_ALL_DATA:
      return initialState;
    default:
      return state;
  }
}
