import React from 'react';
import { Grid } from 'semantic-ui-react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getSubs } from '../redux/actions/dataActions';

import SideMenu from '../util/SideMenu';

import { Segment } from 'semantic-ui-react';

class blog extends React.Component {
  render() {
    let activeItem = 'blog';
    return (
      <Grid columns={2}>
        <SideMenu activeItem={activeItem} />
        <Grid.Column stretched width='12' style={{ marginLeft: '240px' }}>
          <Segment>Welcome to our blog.</Segment>
        </Grid.Column>
      </Grid>
    );
  }
}

blog.propTypes = {
  getSubs: PropTypes.func.isRequired,
  subs: PropTypes.array.isRequired,
  uid: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  subs: state.data.subs,
  uid: state.user.userId,
});

export default connect(mapStateToProps, { getSubs })(blog);
