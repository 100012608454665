// User reducer types
export const SET_USER = 'SET_USER';
export const RESET_USER = 'RESET_USER';
export const LOADING_USER = 'LOADING_USER';
export const STOP_LOADING_USER = 'STOP_LOADING_USER';
export const SET_LOGIN_ERROR = 'SET_LOGIN_ERROR';
export const CLEAR_LOGIN_ERROR = 'CLEAR_LOGIN_ERROR';

// Data reducer types
export const SET_SUBS = 'SET_SUBS';
export const UPLOAD_SUB_STATUS = 'UPLOAD_SUB_STATUS';
export const UPLOAD_SUB_DONE = 'UPLOAD_SUB_DONE';
export const UPLOAD_SUB_FAILED = 'UPLOAD_SUB_FAILED';
export const LOADING_DATA = 'LOADING_DATA';
export const STOP_LOADING_DATA = 'STOP_LOADING_DATA';
export const SET_DATA_ERROR = 'SET_DATA_ERROR';
export const CLEAR_DATA_ERROR = 'CLEAR_DATA_ERROR';
export const RESET_ALL_DATA = 'RESET_ALL_DATA';

// Wiki types
export const LOADING_WIKI = 'LOADING_WIKI';
export const STOP_LOADING_WIKI = 'STOP_LOADING_WIKI';
export const SET_WIKI_ERROR = 'SET_WIKI_ERROR';
export const CLEAR_WIKI_ERROR = 'CLEAR_WIKI_ERROR';
export const SET_WIKI = 'SET_WIKI';
export const SET_WIKI_ARTICLE = 'SET_WIKI_ARTICLE';
