import {
  LOADING_WIKI,
  STOP_LOADING_WIKI,
  SET_WIKI,
  SET_WIKI_ERROR,
  CLEAR_WIKI_ERROR,
  SET_WIKI_ARTICLE,
} from '../types';

const initialState = {
  loading: false,
  articles: null,
  article: null,
  error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_WIKI:
      return {
        ...state,
        loading: true,
      };
    case STOP_LOADING_WIKI:
      return {
        ...state,
        loading: false,
      };
    case SET_WIKI:
      return {
        ...state,
        articles: action.articles,
      };
    case SET_WIKI_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case CLEAR_WIKI_ERROR:
      return {
        ...state,
        error: null,
      };
    case SET_WIKI_ARTICLE:
      return {
        ...state,
        article: action.article,
      };
    default:
      return state;
  }
}
