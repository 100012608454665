import React from 'react';
import { Grid, Dimmer, Loader, Message, List, ListItem } from 'semantic-ui-react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import SideMenu from '../util/SideMenu';

import { Segment } from 'semantic-ui-react';

import { loadWiki, setArticle } from '../redux/actions/wikiActions';
function Art(props) {
  return <ListItem id={props.name}>{props.name}</ListItem>;
  //return <Label>{props.name}</Label>;
}

class wiki extends React.Component {
  updateWiki() {
    const { articles, error, loading } = this.props.wiki;
    let needUpdate = articles === null && error === null && loading === false;

    if (needUpdate) {
      this.props.loadWiki();
    }
  }

  componentDidUpdate() {
    this.updateWiki();
  }

  handleListClick = (e) => {
    //console.log('handleListClick');
    //console.log(e);
    //console.log(e.target.id);

    this.props.setArticle(e.target.id);
  };

  displayArticlesHeaders() {
    const { articles } = this.props.wiki;
    return (
      <List selection bulleted onClick={this.handleListClick}>
        {articles.map((article) => (
          <Art key={article.name} name={article.name} />
        ))}
      </List>
    );
  }

  render() {
    //this.updateWiki();
    let activeItem = 'wiki';
    const { articles, article, error, loading } = this.props.wiki;
    let articleText = null;
    if (article && articles) {
      const filtered = articles.filter((a) => a.name === article);
      if (filtered.length > 0) articleText = filtered[0].text;
    }
    //console.log('articleText');
    //console.log(articleText);
    return (
      <Grid columns={2}>
        <SideMenu activeItem={activeItem} />
        <Grid.Column stretched width='12' style={{ marginLeft: '240px' }}>
          <Segment>Welcome to our Wiki.</Segment>
          {loading && (
            <Dimmer active inverted>
              <Loader size='huge' content={'Loading Wiki...'} />
            </Dimmer>
          )}
          {error && (
            <Message error>
              <h3>Error</h3>
              <p>{error}</p>
            </Message>
          )}
          <h3>Articles</h3>
          {articles && this.displayArticlesHeaders()}

          {articleText && (
            <div>
              <h3>{article}</h3>
              <div dangerouslySetInnerHTML={{ __html: articleText }}></div>
            </div>
          )}
        </Grid.Column>
      </Grid>
    );
  }
}

wiki.propTypes = {
  wiki: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  wiki: state.wiki,
});

const mapActionsToProps = {
  loadWiki,
  setArticle,
};

export default connect(mapStateToProps, mapActionsToProps)(wiki);
