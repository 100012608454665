import React from 'react';
import { Grid, Form, Segment, Button, Header, Message, Image } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { signupUser } from '../redux/actions/userActions';

class signup extends React.Component {
  state = {
    email: '',
    password: '',
    confirmPassword: '',
    fullName: '',
    loading: false,
  };
  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({
      loading: true,
    });
    const newUserData = {
      email: this.state.email,
      password: this.state.password,
      confirmPassword: this.state.confirmPassword,
      fullName: this.state.fullName,
    };
    this.props.signupUser(newUserData);
  };
  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  render() {
    const { email, password, confirmPassword, fullName, loading } = this.state;
    const { loginError } = this.props.user;

    return (
      <Grid textAlign='center' verticalAlign='middle' className='app'>
        <Grid.Column style={{ maxWidth: 450 }}>
          <Image src='/synthesis.png' size='medium' centered />
          <Header as='h3' icon color='blue' textAlign='center'>
            Register for Data Vendor Portal
          </Header>
          <Form onSubmit={this.handleSubmit} size='large'>
            <Segment stacked>
              <Form.Input
                fluid
                name='fullName'
                icon='user'
                iconPosition='left'
                placeholder='Full Name'
                onChange={this.handleChange}
                value={fullName}
                type='text'
              />

              <Form.Input
                fluid
                name='email'
                icon='mail'
                iconPosition='left'
                placeholder='Email Address'
                onChange={this.handleChange}
                value={email}
                type='email'
              />

              <Form.Input
                fluid
                name='password'
                icon='lock'
                iconPosition='left'
                placeholder='Password'
                onChange={this.handleChange}
                value={password}
                type='password'
              />

              <Form.Input
                fluid
                name='confirmPassword'
                icon='repeat'
                iconPosition='left'
                placeholder='Password Confirmation'
                onChange={this.handleChange}
                value={confirmPassword}
                type='password'
              />

              <Button disabled={loading} className={loading ? 'loading' : ''} color='blue' fluid size='large'>
                Submit
              </Button>
            </Segment>
          </Form>
          {loginError && (
            <Message error>
              <h3>Error</h3>
              <p>{JSON.stringify(loginError)}</p>
            </Message>
          )}
          <Message>
            Already a user? <Link to='/login'>Login</Link>
          </Message>
        </Grid.Column>
      </Grid>
    );
  }
}

signup.propTypes = {
  user: PropTypes.object.isRequired,
  signupUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapActionsToProps = {
  signupUser,
};

export default connect(mapStateToProps, mapActionsToProps)(signup);
