export const errorToUser = (err) => {
  console.log(err);
  console.log(err.name);
  let errStr = JSON.stringify(err);
  if (err.response) {
    errStr = JSON.stringify(err.response.data);
  } else if (err.message) {
    try {
      errStr = JSON.parse(err.message).error.message;
    } catch (newErr) {
      errStr = err.message;
    }
  }

  console.log(errStr);
  return errStr;
};
