import React from 'react';
import { Grid, Form, Segment, Button, Header, Message, Image } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { loginUser } from '../redux/actions/userActions';

class Login extends React.Component {
  state = {
    email: '',
    password: '',
    errors: [],
    loading: false,
  };

  handleChange = (event) => this.setState({ [event.target.name]: event.target.value });

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.isFormValid(this.state)) {
      const userData = {
        email: this.state.email,
        password: this.state.password,
      };
      this.props.loginUser(userData);
    }
  };

  isFormValid = ({ email, password }) => email && password;

  handleInputError = (errors, inputName) => {
    return errors.some((error) => error.message.toLowerCase().includes(inputName)) ? 'error' : '';
  };

  render() {
    const { email, password, errors, loading } = this.state;
    const { loginError } = this.props.user;

    return (
      <Grid textAlign='center' verticalAlign='middle' className='app'>
        <Grid.Column style={{ maxWidth: 450 }}>
          <Image src='/synthesis.png' size='medium' centered />
          <Header as='h3' color='blue' textAlign='center'>
            Login to Data Vendor Portal
          </Header>
          <Form onSubmit={this.handleSubmit} size='large'>
            <Segment stacked>
              <Form.Input
                fluid
                name='email'
                icon='mail'
                iconPosition='left'
                placeholder='Email Address'
                onChange={this.handleChange}
                value={email}
                className={this.handleInputError(errors, 'email')}
                type='email'
              />

              <Form.Input
                fluid
                name='password'
                icon='lock'
                iconPosition='left'
                placeholder='Password'
                onChange={this.handleChange}
                value={password}
                className={this.handleInputError(errors, 'password')}
                type='password'
              />

              <Button disabled={loading} className={loading ? 'loading' : ''} color='blue' fluid size='large'>
                Submit
              </Button>
            </Segment>
          </Form>
          {loginError && (
            <Message error>
              <h3>Error</h3>
              <p>{loginError}</p>
            </Message>
          )}
          <Message>
            Don't have an account? <Link to='/signup'>Register</Link>
          </Message>
        </Grid.Column>
      </Grid>
    );
  }
}

Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapActionsToProps = {
  loginUser,
};

export default connect(mapStateToProps, mapActionsToProps)(Login);
