import React from 'react';
//import firebase from "../../firebase";
import { Header, Dropdown, Image } from 'semantic-ui-react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getUserData, logoutUser, uploadImage } from '../redux/actions/userActions';
import { resetAllData } from '../redux/actions/dataActions';

class UserPanel extends React.Component {
  dropdownOptions = () => [
    {
      key: 'user',
      text: (
        <span>
          Signed in as <strong>{this.props.user.fullName}</strong>
        </span>
      ),
      disabled: true,
    },
    {
      key: 'avatar',
      text: (
        <span
          onClick={(e) => {
            e.preventDefault();
            const fileInput = document.getElementById('img_upload');
            fileInput.click();
          }}
        >
          Change Avatar
        </span>
      ),
    },
    {
      key: 'signout',
      text: (
        <span
          onClick={(e) => {
            e.preventDefault();
            this.props.resetAllData();
            this.props.logoutUser();
          }}
        >
          Sign Out
        </span>
      ),
    },
  ];

  imgUpload = (event) => {
    const img = event.target.files[0];
    const formData = new FormData();
    formData.append('img', img, img.name);
    this.props.uploadImage(formData);
  };

  avatarUrl = () => {
    const { user } = this.props;
    return user.imageUrl ? user.imageUrl : 'nophoto.png';
  };

  render() {
    const { user } = this.props;

    return (
      <React.Fragment>
        <input
          type='file'
          id='img_upload'
          accept='image/gif, image/jpeg, image/png'
          hidden='hidden'
          onChange={this.imgUpload}
        ></input>
        <div className='sidebar-logo-alignment-container'>
          <Image src='/synthesis-white.png' width='200px' />
        </div>
        <div className='sidebar-user-alignment-container'>
          <Header style={{ padding: '0.25em' }} as='h4' inverted>
            <Dropdown
              //icon='gear'
              color='white'
              //flex
              trigger={
                <React.Fragment>
                  <div className='float-left'>
                    <Image src={this.avatarUrl()} spaced avatar />
                  </div>
                  <React.Fragment></React.Fragment>
                  <div className='float-left-text'>{user.fullName}</div>
                </React.Fragment>
              }
              options={this.dropdownOptions()}
            />
          </Header>
        </div>
      </React.Fragment>
    );
  }
}

UserPanel.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  getUserData: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  resetAllData: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
});
const mapActionsToProps = { logoutUser, getUserData, uploadImage, resetAllData };

export default connect(mapStateToProps, mapActionsToProps)(UserPanel);
