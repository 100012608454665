import {
  LOADING_DATA,
  STOP_LOADING_DATA,
  SET_DATA_ERROR,
  CLEAR_DATA_ERROR,
  SET_SUBS,
  UPLOAD_SUB_STATUS,
  UPLOAD_SUB_DONE,
  UPLOAD_SUB_FAILED,
  RESET_ALL_DATA,
} from '../types';
import axios from 'axios';
import { firebase } from '../../firebase_db';
import { v4 as uuidv4 } from 'uuid';

import { errorToUser } from '../../util/ErrorToUser';

export const getSubs = () => (dispatch) => {
  dispatch({ type: LOADING_DATA });
  axios
    .get(`/subs`)
    .then((res) => {
      dispatch({
        type: SET_SUBS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.error(`error on data action ${err}`);
      dispatch({
        type: SET_SUBS,
        payload: null,
      });
    })
    .finally(() => {
      dispatch({ type: STOP_LOADING_DATA });
    });
};

export const setError = (error) => (dispatch) => {
  dispatch({ type: SET_DATA_ERROR, error: error });
  dispatch({ type: STOP_LOADING_DATA });
};

export const clearError = () => (dispatch) => {
  dispatch({ type: CLEAR_DATA_ERROR });
};

export const uploadSubProgress = (progress) => (dispatch) => {
  dispatch({ type: UPLOAD_SUB_STATUS, payload: progress });
};

export const uploadSubDone = () => (dispatch) => {
  dispatch({ type: UPLOAD_SUB_DONE });
};

export const uploadSubFailed = () => (dispatch) => {
  dispatch({ type: UPLOAD_SUB_FAILED });
};

export const resetAllData = () => (dispatch) => {
  dispatch({ type: RESET_ALL_DATA });
};

export const uploadCsv = (fileToBeUploaded, uId) => (dispatch) => {
  console.log('fileToBeUploaded');
  console.log(fileToBeUploaded.name);
  const fileExt = fileToBeUploaded.name.split('.').pop();
  if (fileExt !== 'zip' && fileExt !== 'csv') {
    dispatch(setError('Invalid file extension'));
    dispatch(uploadSubFailed());
    return;
  }

  const sid = uuidv4();
  const filePath = `sub/${uId}/${sid}/sub.${fileExt}`;
  const uploadTask = firebase.storage().ref().child(filePath).put(fileToBeUploaded);

  const next = (snapshot) => {
    let uploadProgress = snapshot.bytesTransferred / snapshot.totalBytes;
    dispatch(uploadSubProgress(uploadProgress));
  };
  const error = (error) => {
    console.log('upload error');
    console.log(error);
    dispatch(setError(error.code));
    dispatch(uploadSubFailed());
  };
  const complete = () => {
    console.log('upload complete, fetching url');
    firebase
      .storage()
      .ref()
      .child(filePath)
      .getDownloadURL()
      .then((url) => {
        //console.log('url');
        //console.log(url);

        axios
          .post('/sub', { subId: sid, downloadUrl: url })
          .then(() => {
            dispatch(getSubs());
            dispatch(clearError());
            dispatch(uploadSubDone());
          })
          .catch((err) => {
            console.log('catch1');
            console.log(err);
            dispatch(setError(errorToUser(error)));
            dispatch(uploadSubFailed());
          });
      })
      .catch((err) => {
        console.log('err');
        console.log(err);

        dispatch(setError(errorToUser(error)));
        dispatch(uploadSubFailed());
      });
  };

  uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, next, error, complete);
};

export const updateSub = (subId, needProcessChecked) => (dispatch) => {
  console.log(`updateSub`, subId, needProcessChecked);
  dispatch({ type: LOADING_DATA });

  axios
    .post('/updateSub', { subId: subId, needProcess: needProcessChecked })
    .then(() => {
      dispatch(getSubs());
      dispatch(clearError());
    })
    .catch((err) => {
      console.log('updateSub catch');
      console.log(err);
      dispatch(setError(errorToUser(err)));
    });
};
