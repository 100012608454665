import React, { Fragment } from 'react';
import { Segment, Grid, Image, Dimmer, Loader, Icon, Checkbox } from 'semantic-ui-react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getSubs, setError, clearError, updateSub } from '../redux/actions/dataActions';

import SideMenu from '../util/SideMenu';
import FileUpload from '../util/FileUpload';

import { config, db } from '../firebase_db';

const moment = require('moment');

//<SideMenu activeItem="home"/>
class sim extends React.Component {
  state = { hidden_pics: new Set() };

  componentDidMount() {
    this.props.clearError();
    this.subscribed = false;
    this.lastSubUpdateValue = null;
  }
  componentWillUnmount() {
    if (this.subscribed) this.unsubscribe();
  }

  checkSubscribe() {
    if (!this.subscribed && this.props.userId) {
      this.subscribed = true;
      this.unsubscribe = db
        .collection('users')
        .doc(this.props.userId)
        .onSnapshot(
          (docSnapshot) => {
            console.log('user updated!');
            //console.log(docSnapshot);
            let newVal = docSnapshot.data().subsUpdatedAt;
            if (newVal !== this.lastSubUpdateValue) {
              this.lastSubUpdateValue = newVal;
              this.props.getSubs();
            }
          },
          (err) => {
            console.log(`Encountered error:`, err);
            this.props.setError(JSON.stringify(err));
          }
        );
    }
  }

  render() {
    this.checkSubscribe();
    let activeItem = 'sim';
    const { loading } = this.props.data;

    const Sub = (subProps) => {
      //console.log(props);
      const userId = subProps.userId;
      const subId = subProps.sub.subId;
      //const sub = props.sub;
      const uploaded = subProps.sub.uploaded;
      const checked = subProps.sub.checked;
      const needProcess = subProps.sub.needProcess;
      const needProcessSet = needProcess !== undefined;
      const processed = subProps.sub.processed;

      const needProcessChanged = (e, { checked }) => {
        console.log('needProcessChanged', checked);
        console.log(subProps);
        this.props.updateSub(subId, checked);
      };

      const showPicChanged = (e, { checked }) => {
        console.log('showPicChanged', checked);
        console.log(subProps);
        const { hidden_pics } = this.state;
        let new_hidden_pics = hidden_pics;
        if (checked) {
          new_hidden_pics.delete(subId);
        } else {
          new_hidden_pics.add(subId);
        }
        this.setState({ hidden_pics: new_hidden_pics });
      };

      const StatusIcon = (props) => {
        if (props.loading) return <Icon loading color='grey' name='spinner' />;
        else return <Icon color='blue' name='check' />;
      };

      const OnePic = (thisProps) => {
        const pic_type = thisProps.pic_type;
        const feature = thisProps.feature;
        const filename = `${feature}_${pic_type}.png`;
        return (
          <Image
            className='image-hover'
            //className='image'
            width='200px'
            src={`https://storage.googleapis.com/${config.storageBucket}%2Fsub%2F${userId}%2F${subId}%2F${filename}?alt=media`}
          />
        );
      };

      const PicsRow = (thisProps) => {
        const feature = thisProps.feature;
        return (
          <Grid.Row>
            {subProps.sub.pic_types
              ? subProps.sub.pic_types.map((pic_type) => (
                  <OnePic subs={subProps.subs} key={pic_type} pic_type={pic_type} feature={feature} />
                ))
              : ''}
          </Grid.Row>
        );
      };

      const AllPics = () => {
        return (
          <Segment>
            <div></div>
            <Grid>
              {subProps.sub.features
                ? subProps.sub.features.map((feature) => <PicsRow key={feature} feature={feature} />)
                : ''}
            </Grid>
          </Segment>
        );
      };

      return (
        <Segment>
          <p>
            Submission {subProps.sub.subId} uploaded {moment(subProps.sub.createdAt).fromNow()} (
            {moment(subProps.sub.createdAt).toLocaleString()}){' '}
          </p>
          <div>
            <StatusIcon loading={!uploaded} />
            File upload status
          </div>
          <div>
            <StatusIcon loading={!checked} />
            File check status
          </div>
          <div>
            {needProcess === true && (
              <Fragment>
                <StatusIcon loading={!processed} />
                {'File process status  '}
              </Fragment>
            )}
            <Checkbox
              label='Need process?'
              defaultIndeterminate={!needProcessSet}
              checked={needProcess}
              onChange={needProcessChanged}
            ></Checkbox>
          </div>
          {checked && (
            <div>
              <Checkbox
                label='Show images'
                checked={!this.state.hidden_pics.has(subId)}
                onChange={showPicChanged}
              ></Checkbox>
            </div>
          )}
          {checked && !this.state.hidden_pics.has(subId) !== false && AllPics()}

          {checked && false && (
            <div>
              <Image
                className='image-hover'
                width='200px'
                src={`https://storage.googleapis.com/${config.storageBucket}%2Fsub%2F${userId}%2F${subId}%2Fcorr.png?alt=media`}
              />
              <a
                href={`https://storage.googleapis.com/${config.storageBucket}%2Fsub%2F${userId}%2F${subId}%2Fcorr.pdf`}
              >
                Download as pdf
              </a>
            </div>
          )}
        </Segment>
      );
    };

    const displaySubs = (userId, subs) => subs.map((sub) => <Sub key={sub.subId} sub={sub} userId={userId} />);

    return (
      <Grid columns={2}>
        <SideMenu activeItem={activeItem} />
        <Grid.Column stretched width='12' style={{ marginLeft: '240px' }}>
          {loading && (
            <Dimmer active inverted>
              <Loader size='huge' content={'Loading subs...'} />
            </Dimmer>
          )}
          <Segment>
            Please use this form to upload a "csv" file with data
            <FileUpload />
          </Segment>
          {!loading && this.props.subs && displaySubs(this.props.userId, this.props.subs)}
        </Grid.Column>
      </Grid>
    );
  }
}

sim.propTypes = {
  getSubs: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  subs: PropTypes.array.isRequired,
  userId: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  data: state.data,
  subs: state.data.subs,
  userId: state.user.userId,
  user: state.user,
});

const mapActionsToProps = {
  getSubs,
  setError,
  clearError,
  updateSub,
};

export default connect(mapStateToProps, mapActionsToProps)(sim);
