import { RESET_USER, SET_USER, LOADING_USER, STOP_LOADING_USER, SET_LOGIN_ERROR, CLEAR_LOGIN_ERROR } from '../types';

const initialState = {
  authenticated: false,
  loading: false,
  userId: '',
  fullName: '',
  imageUrl: '/nophoto.png',
  loginError: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case RESET_USER:
      return initialState;
    case SET_USER:
      return {
        authenticated: true,
        ...action.payload,
        loading: state.loading,
      };
    case LOADING_USER:
      return {
        ...state,
        loading: true,
      };
    case STOP_LOADING_USER:
      return {
        ...state,
        loading: false,
      };
    case SET_LOGIN_ERROR:
      return {
        ...state,
        loginError: action.error,
      };
    case CLEAR_LOGIN_ERROR:
      return {
        ...state,
        loginError: null,
      };
    default:
      return state;
  }
}
