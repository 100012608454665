import React from 'react';
import { Button, Icon, Message, Progress, Label } from 'semantic-ui-react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getUserData } from '../redux/actions/userActions';
import { uploadCsv } from '../redux/actions/dataActions';
//import store from '../redux/store';

class FileUpload extends React.Component {
  handleCsvUpload = (event) => {
    let files = event.target.files;
    if (files.length > 0) {
      const zip = files[0];

      this.props.uploadCsv(zip, this.props.user.userId);
    }
  };
  handleSubmit = () => {
    const fileInput = document.getElementById('csvInput');
    fileInput.click();
  };

  displayErrors = (error) => {
    return error ? <p>{error}</p> : '';
  };

  isUploadRunning = () => {
    //console.log('this.props.uploadStatus');
    //console.log(`${this.props.data.uploadStatus}`);
    return this.props.data.uploadStatus < 1;
  };

  displayUploadStatus = () => {
    return this.isUploadRunning() ? 'Uploading...' : 'Upload done!';
  };

  render() {
    const error = this.props.data.error;
    return (
      <React.Fragment>
        <div>
          <br />
          <input type='file' id='csvInput' hidden='hidden' onChange={this.handleCsvUpload} />
          <Button color='blue' onClick={this.handleSubmit}>
            <Icon name='file outline' className='sidebar-icon' />
            Upload CSV
          </Button>
          {this.props.data.uploadStatus && <Progress percent={this.props.data.uploadStatus * 100} autoSuccess />}
          {this.props.data.uploadStatus > 0 && <Label>{this.displayUploadStatus()}</Label>}
          {error && (
            <Message error>
              <h3>Error</h3>
              {this.displayErrors(error)}
            </Message>
          )}
        </div>
      </React.Fragment>
    );
  }
}

FileUpload.propTypes = {
  getUserData: PropTypes.func.isRequired,
  uploadCsv: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
  data: state.data,
});

const mapActionsToProps = { getUserData, uploadCsv };

export default connect(mapStateToProps, mapActionsToProps)(FileUpload);
