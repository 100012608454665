import { RESET_USER, SET_USER, LOADING_USER, STOP_LOADING_USER, SET_LOGIN_ERROR, CLEAR_LOGIN_ERROR } from '../types';

import axios from 'axios';

import { firebase } from '../../firebase_db';

import { errorToUser } from '../../util/ErrorToUser';

const setLoginError = (err) => {
  return {
    type: SET_LOGIN_ERROR,
    error: err,
  };
};

const clearLoginError = () => {
  return {
    type: CLEAR_LOGIN_ERROR,
  };
};

const loginStorage = (userData) => {
  return firebase.auth().signInWithEmailAndPassword(userData.email, userData.password);
};

export const loginUser = (userData) => (dispatch) => {
  dispatch({ type: LOADING_USER });

  const promise1 = axios.post('/login', userData);
  const promise2 = loginStorage(userData);

  const promises = [promise1, promise2];

  return Promise.all(promises)
    .then((values) => {
      //console.log('values');
      //console.log(values);
      const res_http = values[0];

      setAuthorizationHeader(res_http.data.token);
      //const res_firebase = values[1];

      return dispatch(getUserData(dispatch));
    })
    .catch((err) => {
      dispatch(logoutUser());
      dispatch(setLoginError(errorToUser(err)));
      dispatch({ type: STOP_LOADING_USER });
    });
};

export const signupUser = (newUserData) => (dispatch) => {
  dispatch({ type: LOADING_USER });

  return axios
    .post('/signup', newUserData)
    .then(() => {
      dispatch(loginUser(newUserData));
    })
    .catch((err) => {
      dispatch(setLoginError(errorToUser(err)));
      dispatch({ type: STOP_LOADING_USER });
    });
};

export const logoutUser = () => (dispatch) => {
  localStorage.removeItem('FBIdToken');
  delete axios.defaults.headers.common['Authorization'];
  firebase.auth().signOut();
  dispatch({ type: RESET_USER });
};

export const getUserData = () => (dispatch) => {
  //console.log('getUserData');
  //console.trace();
  axios
    .get('/user')
    .then((res) => {
      dispatch({
        type: SET_USER,
        payload: res.data,
      });
      dispatch(clearLoginError);
    })
    .catch((err) => dispatch(setLoginError(errorToUser(err))))
    .finally(() => {
      dispatch({ type: STOP_LOADING_USER });
    });
};

export const uploadImage = (formData) => (dispatch) => {
  dispatch({ type: LOADING_USER });
  axios
    .post('/user/image', formData)
    .then(() => {
      dispatch(getUserData());
    })
    .catch((err) => console.log(`uploadImage: ${err}`));
};

export const getImage = (formData) => (dispatch) => {
  axios
    .get('/user/avatar', formData)
    .then((res) => {
      dispatch(getUserData());
    })
    .catch((err) => console.log(`uploadImage: ${err}`));
};

const setAuthorizationHeader = (token) => {
  const FBIdToken = `Bearer ${token}`;
  localStorage.setItem('FBIdToken', FBIdToken);
  axios.defaults.headers.common['Authorization'] = FBIdToken;
};
