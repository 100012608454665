import {
  LOADING_WIKI,
  STOP_LOADING_WIKI,
  SET_WIKI,
  SET_WIKI_ERROR,
  CLEAR_WIKI_ERROR,
  SET_WIKI_ARTICLE,
} from '../types';

import { db } from '../../firebase_db';

export const loadWiki = () => (dispatch) => {
  dispatch(setLoadingWiki());

  let coll = db.collection('wiki');
  coll
    .get()
    .then((res) => {
      //console.log(res);
      //res.forEach((doc) => console.log(doc.id));
      //res.forEach((doc) => console.log(doc.data().name));

      let res1 = res.docs.map((x) => x.data());

      dispatch(setWiki(res1));
      dispatch(clearWikiError());
      dispatch(setStopLoadingWiki());
    })
    .catch((err) => {
      console.log(err);
      dispatch(setWikiError(JSON.stringify(err)));
      dispatch(setStopLoadingWiki());
    });
};

export const setArticle = (article) => {
  return {
    type: SET_WIKI_ARTICLE,
    article: article,
  };
};

const setLoadingWiki = () => {
  return {
    type: LOADING_WIKI,
  };
};

const setStopLoadingWiki = () => {
  return {
    type: STOP_LOADING_WIKI,
  };
};

const setWiki = (articles) => {
  return {
    type: SET_WIKI,
    articles: articles,
  };
};

const setWikiError = (error) => {
  return {
    type: SET_WIKI_ERROR,
    error: error,
  };
};

const clearWikiError = () => {
  return {
    type: CLEAR_WIKI_ERROR,
  };
};
