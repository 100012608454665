import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import axios from 'axios';
import './App.css';

// Redux
import { Provider } from 'react-redux';
import store from './redux/store';
import { connect } from 'react-redux';

// Semantic UI
import 'semantic-ui-less/semantic.less';
import Spinner from './util/Spinner';

// Components
import AuthRoute from './util/AuthRoute';
import NoAuthRoute from './util/NoAuthRoute';

// Pages
import home from './pages/home';
import sim from './pages/sim';
import blog from './pages/blog';
import wiki from './pages/wiki';
import login from './pages/login';
import signup from './pages/signup';

import { config } from './firebase_db';

axios.defaults.baseURL = config.apiUrl;
/*
const token = localStorage.FBIdToken;
if (token) {
  const decodedToken = jwtDecode(token);
  if (decodedToken.exp * 1000 < Date.now()) {
    store.dispatch(logoutUser());
    window.location.href = '/login';
  } else {
    store.dispatch({ type: SET_AUTHENTICATED });
    axios.defaults.headers.common['Authorization'] = token;
    store.dispatch(getUserData());
  }
}
*/

function Routes(props) {
  const loading = props.loading;
  return loading ? (
    <Spinner />
  ) : (
    <div className='container'>
      <Switch>
        <NoAuthRoute exact path='/' component={wiki} />
        <NoAuthRoute exact path='/home' component={home} />
        <NoAuthRoute exact path='/sim' component={sim} />
        <NoAuthRoute exact path='/blog' component={blog} />
        <NoAuthRoute exact path='/wiki' component={wiki} />
        <AuthRoute exact path='/login' component={login} />
        <AuthRoute exact path='/signup' component={signup} />
      </Switch>
    </div>
  );
}

const mapStateToProps = (state) => ({
  loading: state.user.loading,
});

const RoutesWithAuth = withRouter(connect(mapStateToProps)(Routes));

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Router>
          <RoutesWithAuth />
        </Router>
      </Provider>
    );
  }
}

export default App;
